<template>
  <div>
    <privatePDF v-if="$route.params.id" :id="getID()"/>
  </div>
</template>
<script>
export default {
  components: {
    privatePDF () { return import('@/components/assistentPrivate/pdf') }
  },
  props: {},
  data () {
    return {
    }
  },
  methods: {
    getID () {
      return atob(atob(this.$route.params.id))
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
